<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/">首页</router-link>><router-link to="/info">个人中心</router-link>><a
            href="javascript:;">修改手机号</a>
        </div>
      </div>
      <div class="personal per_info pt30 m1440 flex-box flex-col-start">
        <div class="left_nav fs18">
          <router-link to="/info">基本信息</router-link>
          <router-link to="/certificate">证书抬头</router-link>
          <router-link to="/maintain_list">开票信息</router-link>
          <router-link to="/address_list">收件地址</router-link>
          <router-link to="/invoices_status">发票管理</router-link>
          <!--mao 20250324 -->
          <!-- <router-link to="/contract_management">合同管理</router-link> -->
          <!-- end -->
          <router-link to="/change_password">修改密码</router-link>
          <router-link to="/change_phone" class="active">修改手机号</router-link>
          <a @click="cancellation()" class="cursor" href="javascript:;">删除账户</a>
        </div>
        <div class="right_box flex-grow-1">
          <div class="fs18 fwb pb30">修改手机号</div>
          <div class="flex-box pb30">
            <div class="fs16 title">新手机号</div>
            <input type="text" v-model="tel" class="phone fs16" placeholder="请输入要更换的手机号" />
            <div class="pl10 col_nor cursor btn2" v-if="show" @click="sendSms">发送验证码</div>
            <div v-else class="pl10 col_nor cursor btn2">{{ count }}s</div>
            <!-- <div class="pl10 col_nor cursor">获取验证码</div> -->
          </div>
          <div class="flex-box pb30">
            <div class="fs16 title">验证码</div>
            <input type="text" v-model="smsCode" class="phone fs16" placeholder="请输入验证码" />
          </div>
          <div class="save fs16" @click="submit">保存</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import cookie from "vue-cookies";
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      tel: '',
      show: true,
      count: 60,
      is_reg: false,//防止多次点击
      smsCode: ''
    };
  },
  methods: {

    sendSms: function () {
      let that = this;
      let tel = that.tel
      let userinfo = cookie.get("huanbaoqiao_userinfo");
      if (userinfo.tel == tel) {
        that.$message.error("当前手机号和新手机号一致，无需更改！");
        return;
      }
      if (tel != '') {
        if (!(/^1[3456789]\d{9}$/.test(tel))) {
          that.$message.error("请填写正确的手机号码！");
          return;
        }
      } else {
        that.$message.error("请输入手机号！");
        return;
      }

      that.$api.checkUserStatus({ tel: tel }).then((s_res) => {
        if (s_res.resCode == 1002) {
          that.$api.apiPostSend({ tel: tel })
            .then((res) => {
              // console.log(data_res);
              if (res.resCode == 0) {
                const TIME_COUNT = 60;
                if (!that.timer) {
                  that.count = TIME_COUNT;
                  that.show = false;
                  that.timer = setInterval(() => {
                    if (that.count > 0 && that.count <= TIME_COUNT) {
                      that.count--;
                    } else {
                      that.show = true;

                      clearInterval(that.timer);

                      that.timer = null;
                    }
                  }, 1000);
                }
                // that.user.code = res.root
              } else {
                that.$message.error(res.resMsg);
              }
            });
        }else if(s_res.resCode == 0){
          that.$message.error('手机号已存在');
        }  else {
          that.$message.error(s_res.resMsg);
        }
      });
    },

    submit() {
      let that = this;
      let tel = that.tel
      let userinfo = cookie.get("huanbaoqiao_userinfo");
      if (tel != '') {
        if (!(/^1[3456789]\d{9}$/.test(tel))) {
          that.$message.error("请填写正确的手机号码！");
          return;
        }
      } else {
        that.$message.error("请输入手机号！");
        return;
      }
      if (!that.smsCode) {
        that.$message.error("请输入验证码！");
        return;
      }
      that.$api.checkUserStatus({ tel: tel }).then((s_res) => {
        if (s_res.resCode == 1002) {
          let data = {
            id: userinfo.id,
            tel: tel,
            smsCode: that.smsCode
          }
          that.$api.updateUserInfo(data).then((res) => {
            if (res.resCode == 0) {
              that.$message.success('手机号更改成功，请重新登录');
              cookie.remove("huanbaoqiao_token");
              cookie.remove("huanbaoqiao_userinfo");
              setTimeout(function () {
                that.$router.push("/login");
              }, 800)
            } else {
              that.$message.error(res.resMsg);
            }
          });
        }else if(s_res.resCode == 0){
          that.$message.error('手机号已存在');
        } else {
          that.$message.error(s_res.resMsg);
        }
      });
    },


    //删除
    cancellation() {
      let that = this;
      MessageBox.confirm("是否确定删除当前账户?", "提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            let userinfo = cookie.get("huanbaoqiao_userinfo");
            that.$api.logOffAccount({ userId: userinfo.id }).then((res) => {
              if (res.resCode == 0) {
                that.$message.success('删除成功');
                cookie.remove("huanbaoqiao_token");
                cookie.remove("huanbaoqiao_userinfo");
                setTimeout(function () {
                  that.$router.push("/");
                }, 800)
              } else {
                that.$message.error(res.resMsg);
              }
            });

          }
        })
        .catch((err) => {
          //    console.log(err);
        });
    },



  }
};
</script>
